import React from "react";

function SystemStatus() {
  return (
    <div>
      <table>
        <thead>
          <th scope="col">Communications APIs</th>
          <th scope="col">Español</th>
          <th scope="col">Français</th>
          <th scope="col">Deutsch</th>
        </thead>
        <tbody>
          <tr>
            <td data-label="Account"></td>
            <td data-label="Due Date">
              Americas: +1 415 941 5878 <br />
              Spain: +34 918295283
            </td>
            <td data-label="Amount">+33 1 77 68 24 97</td>
            <td data-label="Period"></td>
          </tr>
        </tbody>
        <thead>
          <th scope="col"></th>
          <th scope="col">Español</th>
          <th scope="col">Français</th>
          <th scope="col">Deutsch</th>
        </thead>
        <tbody>
          <tr>
            <td data-label="Account"></td>
            <td data-label="Due Date">
              Americas: +1 415 941 5878 <br />
              Spain: +34 918295283
            </td>
            <td data-label="Amount">+33 1 77 68 24 97</td>
            <td data-label="Period"></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default SystemStatus;
